export const JC_AUG_V1 = '/4c1b9v1916'
export const COME_BACK_5 = '/comeback5'
export const SOMETHING = '/something'
export const FOREVER_35 = '/forever35'
export const SUBSCRIPTION_DELAY = '/subscription-delay'
export const DELAY_EXPIRED = '/expired'

// Don't forget to add new active landing page here
export const ALL_LANDING_PAGES = [SUBSCRIPTION_DELAY]
// Don't forget to add new active landing page here
const subscriptionDelayName = 'subscription-delay'
export const getLandingVersion = pathname => {
  const pageName = pathname ? pathname : null
  switch (pageName) {
    case SUBSCRIPTION_DELAY:
      return subscriptionDelayName
    default:
      return ''
  }
}

export const partnerIFrames = [
  {
    key: 'cnbc',
    path: '/cnbc',
    src: 'https://www.cnbc.com/2018/12/07/beauty-company-rejects-traditional-skin-regimens-claims-ai-is-answer.html',
    seoTitle: 'Startup Proven Beauty says A.I. is key to having flawless skin',
    seoDescription:
      'The U.S. facial skincare market is worth an estimated $7 billion. Yet despite the wealth of products and information available promising flawless, ageless skin, consumers are frustrated by the low success rate. Start-up Proven Skincare believes personalized products have a much higher efficacy level and is using AI to determine the best ingredients for each individual.'
  },
  {
    key: 'refinery29',
    path: '/refinery29',
    src: 'https://www.refinery29.com/en-us/proven-personalized-skin-care-solution',
    seoTitle: 'Proven Skincare: Personalized SPF, Face Cream, Cleanser',
    seoDescription:
      'Using artificial intelligence, PROVEN Skincare personalizes all your products based on factors like lifestyle, diet, environment, and skin type.'
  }
  // these partners do not allow us to iFrame their sites
  // {
  //   key: "byrdie",
  //   path: "/byrdie",
  //   src: "https://www.byrdie.com/proven-skincare-review",
  //   seoTitle: "Proven Uses AI to Completely Personalize Your Skincare",
  //   seoDescription: "We review Proven and learn about why the founders started the company. Read all about it here",
  // },
  // {
  //   key: "zoereport",
  //   path: "/zoereport",
  //   src: "https://www.thezoereport.com/p/proven-skincare-the-new-line-that-doesnt-have-a-single-serum-13067051",
  //   seoTitle: "Proven Skincare: The New Line That Doesn't Have A Single Serum",
  //   seoDescription: "In an era where #shelfies outnumber #selfies, skincare is king. It’s obvious that on some level, beauty enthusiasts identify with their products, and consider their routine a form of self-care and self-expression — but all of the options can be…",
  // },
]
