export const CONTAINER_ID = 'LOCK_VIEW_CONTAINER'
export const CLIENT_ID = process.env.AUTH0_CLIENT_ID || 'j3E9xYuUBDuyrqMf-M2iQJmuLKa6CGqj'
export const DOMAIN = process.env.AUTH0_DOMAIN || 'logina.tryproven.com'
export const STRIPE_KEY = process.env.STRIPE_KEY || 'pk_test_dHambD80ignCd6fje3WaB8eW'
export const AUTH0_SCOPE = process.env.AUTH0_SCOPE || 'openid profile email'
export const AUTH0_RESPONSE_TYPE = process.env.AUTH0_RESPONSE_TYPE || 'token id_token'
export const AUTH0_REALM = process.env.AUTH0_REALM || 'Username-Password-Authentication'
export const SEGMENT_KEY = process.env.SEGMENT_KEY || 'x7w5RHI6JrEAvCKhdSSdUQbflTIXaPgp'
export const COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID || '6f0aqe4q3gf4p9tci07pm5dcv1'
export const COGNITO_USER_POOL_ID = process.env.COGNITO_USER_POOL_ID || 'us-east-2_1RD67bPRa'
export const COGNITO_REGION = process.env.COGNITO_REGION || 'us-east-2'
export const COGNITO_APP_DOMAIN =
  process.env.COGNITO_APP_DOMAIN || 'proven-staging.auth.us-east-2.amazoncognito.com'
export const COGNITO_REDIRECT_SIGNIN_URL = process.env.BASE_URL || 'http://localhost:3002'
export const COGNITO_REDIRECT_SIGNOUT_URL = `${
  process.env.BASE_URL || 'http://localhost:3002'
}?openLoginSlide=1`
